body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
